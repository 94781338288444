import React from "react"
import { useEffect, useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"
import { getParameterByName } from "../shared/utils"
import { checkPayments } from "../handlers"
import styled, { css } from "styled-components"
import { commaPipe } from "ruucm-util"
import * as System from "../../design-system"

const Wrap = styled.div`
  padding-top: 200px;
  padding-bottom: 500px;
  padding-left: 20px;
  max-width: 500px;
  margin: 0 auto;
`
const Title = styled.div`
  font-family: "NanumSquare";
  font-weight: 800;
  font-size: 18px;
  color: #2348a7;
  strong {
    color: #e9484d;
  }
`
const Info = styled.div`
  font-family: "NanumSquare";
  font-weight: 400;
  font-size: 16px;
  color: #2348a7;
  margin-top: 30px;
  line-height: 30px;
  strong {
    color: #e9484d;
  }
`

const Page = () => {
  const [orderData, setOrderData] = useState(null)
  useEffect(() => {
    let imp_uid = getParameterByName("imp_uid")
    if (imp_uid) {
      console.log("checkPayments!")
      checkPayments(imp_uid, setOrderData)
    }
  }, [])
  return (
    <Layout>
      <SEO title="Order Complete" />

      <Wrap>
        {(() => {
          switch (orderData) {
            case null:
              return <div>주문 결과 조회중.. (화면을 벗어나지 마세요)</div>
            case "error":
              return <div>에러</div>
            default:
              return (
                <div>
                  <Title>클래스 주문이 성공적으로 접수 되었습니다</Title>
                  <Info>
                    주문내역 : {commaPipe(orderData.amount)} 원
                    <br />
                    주문번호 : {orderData.apply_num}
                    <br />
                    <br />
                  </Info>
                </div>
              )
          }
        })()}
      </Wrap>
    </Layout>
  )
}

export default Page
